import React, { useState, useEffect, useRef } from 'react';
import Layout from '../../../layouts/global/Layout';
import { useUserSites } from '../../../hooks/useUserSites';
import { platformIcons } from './platformIcons';
import { useAdPlatformIntegrations } from './useAdsPlatform';
import { TestingPlatforms } from './AdsPlatforms';

interface Subscription {
  website: {
    idsite: string;
    name: string;
  };
}

interface Website {
  id: string;
  name: string;
}

interface PlatformStatus {
  partner: string;
  status: 'connected' | 'disconnected';
  tokens: Array<{
    access_token: string;
    refresh_token?: string;
    websiteId: number;
    expiry_date?: number;
    id?: string;
    token_type: string;
    email?: string;
    username?: string;
  }>;
}

interface PlatformStatuses {
  [platform: string]: {
    [websiteId: string]: PlatformStatus;
  };
}

type Partner = 'facebook' | 'google' | 'x' | 'tiktok';

export default function Integrations() {
  const { loadUserProfile, errorMessage: userProfileError, loading: userProfileLoading } = useUserSites();
  const [userProfile, setUserProfile] = useState(null);
  const [websites, setWebsites] = useState<Website[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedWebsite, setSelectedWebsite] = useState<Website | null>(null);
  const [platformStatuses, setPlatformStatuses] = useState<PlatformStatuses>({});
  const dropdownRef = useRef<HTMLDivElement>(null);

  const platforms: Partner[] = ['google', 'facebook', 'x', 'tiktok'];

  const [loadingPlatforms, setLoadingPlatforms] = useState<{ [key: string]: boolean }>(
    platforms.reduce((acc, platform) => ({ ...acc, [platform]: true }), {})
  );

  const [userId, setUserId] = useState<string | null>(null);

  const {
    getAllStatus,
    disconnectPlatform,
    authorizePlatform,
    exchangeCode,
    errorMessage: adPlatformError,
    loading: adPlatformLoading
  } = useAdPlatformIntegrations();

  // New state for modal
  const [showModal, setShowModal] = useState(false);
  const [platformToDisconnect, setPlatformToDisconnect] = useState<Partner | null>(null);

  const saveSelectedWebsite = (website: Website) => {
    localStorage.setItem('selectedWebsite', JSON.stringify(website));
  };

  const updatePlatformStatuses = async (website: Website) => {
    if (userId) {
      setLoadingPlatforms(platforms.reduce((acc, platform) => ({ ...acc, [platform]: true }), {}));

      const statuses = await getAllStatus(userId, [parseInt(website.id)]);
      console.log('Statuses fetched:', statuses);
      if (statuses) {
        setPlatformStatuses(statuses);
      }

      setLoadingPlatforms(platforms.reduce((acc, platform) => ({ ...acc, [platform]: false }), {}));
    }
  };

  useEffect(() => {
    const initializeWebsiteAndStatuses = async () => {
      const savedWebsiteJson = localStorage.getItem('selectedWebsite');
      if (savedWebsiteJson) {
        const savedWebsite = JSON.parse(savedWebsiteJson) as Website;
        setSelectedWebsite(savedWebsite);
  
        if (!userId) {
          const profileData = await loadUserProfile({});
          if (profileData) {
            setUserId(profileData.subscription_details.subscription[0]?.account_id.toString());
          }
        }
  
        if (userId) {
          await updatePlatformStatuses(savedWebsite);
        }
      }
    };
  
    initializeWebsiteAndStatuses();
  }, []);

  useEffect(() => {
    const fetchStatusesIfNeeded = async () => {
      const savedWebsiteJson = localStorage.getItem('selectedWebsite');
      if (savedWebsiteJson && userId) {
        const savedWebsite = JSON.parse(savedWebsiteJson) as Website;
        await updatePlatformStatuses(savedWebsite);
      }
    };

    fetchStatusesIfNeeded();
  }, [userId]);

  useEffect(() => {
    const fetchUserProfile = async () => {
      const profileData = await loadUserProfile({});
      if (profileData) {
        setUserProfile(profileData);
        const accountId = profileData.subscription_details.subscription[0]?.account_id;
        if (accountId) {
          setUserId(accountId.toString());
        }
        const sites: Website[] = profileData.subscription_details.subscription.map((sub: Subscription) => ({
          id: sub.website.idsite,
          name: sub.website.name
        }));
        setWebsites(sites);
    
        const savedWebsiteJson = localStorage.getItem('selectedWebsite');
        if (savedWebsiteJson) {
          const savedWebsite = JSON.parse(savedWebsiteJson) as Website;
          if (sites.some(site => site.id === savedWebsite.id)) {
            setSelectedWebsite(savedWebsite);
            await updatePlatformStatuses(savedWebsite);
          } else {
            localStorage.removeItem('selectedWebsite');
          }
        }
      }
    };
    fetchUserProfile();

    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    const handleRouteChange = () => {
      const savedWebsiteJson = localStorage.getItem('selectedWebsite');
      if (savedWebsiteJson && userId) {
        const savedWebsite = JSON.parse(savedWebsiteJson) as Website;
        updatePlatformStatuses(savedWebsite);
      }
    };

    window.addEventListener('popstate', handleRouteChange);

    return () => {
      window.removeEventListener('popstate', handleRouteChange);
    };
  }, [userId]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const encodedCode = urlParams.get('code');

    if (encodedCode) {
      handleCodeExchange();
    }
  }, []);

  const handleWebsiteSelection = async (site: Website) => {
    setSelectedWebsite(site);
    setIsOpen(false);
    setSearchTerm('');
    saveSelectedWebsite(site);
    if (userId) {
      await updatePlatformStatuses(site);
    }
  };

  const handlePlatformAction = async (platform: Partner) => {
    if (!selectedWebsite || !userId) return;

    const websiteId = parseInt(selectedWebsite.id);
    const currentStatus = platformStatuses[platform]?.[websiteId]?.status;

    if (currentStatus === 'connected') {
      setShowModal(true);
      setPlatformToDisconnect(platform);
    } else {
      setLoadingPlatforms(prev => ({ ...prev, [platform]: true }));

      try {
        const authInfo: {
          partner: Partner;
          userId: string;
          websiteIds: number[];
          twitterCodeVerifier?: string;
          state?: string;
        } = {
          partner: platform,
          userId: userId,
          websiteIds: [websiteId]
        };

        const response = await authorizePlatform(platform, userId, [websiteId]);
        if (response && typeof response === 'object' && 'url' in response) {
          if (platform === 'x') {
            authInfo.twitterCodeVerifier = response.codeVerifier;
            authInfo.state = response.state;
          }
          localStorage.setItem('authorizationInfo', JSON.stringify(authInfo));
          window.location.href = response.url;
        }
      } catch (error) {
        // logs
      } finally {
        setLoadingPlatforms(prev => ({ ...prev, [platform]: false }));
      }
    }
  };

  const handleDisconnect = async () => {
    if (!platformToDisconnect || !selectedWebsite || !userId) return;

    const websiteId = parseInt(selectedWebsite.id);
    setLoadingPlatforms(prev => ({ ...prev, [platformToDisconnect]: true }));

    try {
      await disconnectPlatform(platformToDisconnect, userId, [websiteId]);

      const updatedStatuses = await getAllStatus(userId, [websiteId]);
      if (updatedStatuses) {
        setPlatformStatuses(updatedStatuses);
      }
    } catch (error) {
      // log
    } finally {
      setLoadingPlatforms(prev => ({ ...prev, [platformToDisconnect]: false }));
      setShowModal(false);
      setPlatformToDisconnect(null);
    }
  };

  const handleCodeExchange = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const encodedCode = urlParams.get('code');

    if (encodedCode) {
      const code = decodeURIComponent(encodedCode);
      const authorizationInfo = localStorage.getItem('authorizationInfo');
      if (authorizationInfo) {
        const { partner, userId, websiteIds, twitterCodeVerifier, state } = JSON.parse(authorizationInfo);

        try {
          let result;
          if (partner === 'x') {
            result = await exchangeCode(partner, code, userId, websiteIds, twitterCodeVerifier, state);
          } else {
            result = await exchangeCode(partner, code, userId, websiteIds);
          }

          if (result) {
            if (selectedWebsite) {
              updatePlatformStatuses(selectedWebsite);
            }
          }
        } catch (error) {
          console.error('Error during code exchange:', error);
        } finally {
          localStorage.removeItem('authorizationInfo');
          window.history.replaceState({}, document.title, window.location.pathname);
        }
      } else {
        console.error('No authorization info found in localStorage');
      }
    }
  };

  const filteredWebsites = websites.filter(site =>
    site.name && site.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Layout>
      <div className="mx-auto rounded-lg lg:p-8">
        <div className='mb-14'>
          <h1 className="text-3xl font-bold mb-2">Integrations</h1>
          <p className="text-gray-600 text-[16px]">Connect your ad platforms to see your data across your heatmaps</p>
        </div>
        <div className='px-6 py-12 border rounded-md mb-8'>
          <div className="bg-white rounded-lg">
            <h2 className="text-lg font-semibold text-[#4B5563]">Select your Website</h2>
            <p className="text-sm text-gray-600 mb-5">Ad platforms are synced to individual websites, select one from your account:</p>

            <div>
              <label className='text-[12px]'>WEBSITES</label>
              <div className="relative lg:w-[40%] mt-2" ref={dropdownRef}>
              <div
  className="w-full px-2 py-3 border border-[#8F99A3]/50 rounded-lg text-[14px] cursor-pointer flex justify-between items-center"
  onClick={() => setIsOpen(!isOpen)}>
  <span>{selectedWebsite ? selectedWebsite.name : 'Select a Website'}</span>
  <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
  </svg>
</div>

                {isOpen && (
                  <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-lg shadow-lg">
                    <input
                      type="text"
                      placeholder="Search websites..."
                      className="w-full p-2 border-b border-gray-300"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)} />
                    <ul className="max-h-80 overflow-auto">
                      {websites.length === 0 ? (
                        <li className="p-2 text-gray-600">Loading sites...</li>
                      ) : filteredWebsites.length === 0 ? (
                        <li className="p-2 text-gray-600">No website found</li>
                      ) : (
                        filteredWebsites.map((site) => (
                          <li
                            key={site.id}
                            className="p-2 hover:bg-gray-100 cursor-pointer"
                            onClick={() => handleWebsiteSelection(site)}>
                            {site.name || 'Unnamed Site'}
                          </li>
                        ))
                      )}
                    </ul>
                  </div>
                )}
              </div>
            </div>

            <h2 className="text-lg font-semibold text-[#4B5563] mt-8 mb-2">Ad Platforms</h2>
            <p className="text-sm text-gray-600 mb-10">Sign in to your preferred Ads account</p>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
              {platforms.map((platform) => {
                const platformStatus =
                  platformStatuses[platform]?.[selectedWebsite?.id ?? ''];

                return (
                  <div
                    key={platform}
                    className="bg-white rounded-lg shadow-lg border border-[#8F99A3]/50 p-4 flex flex-col items-center mb-2"
                  >
                    {platformIcons[platform] && <div className="mb-2">{platformIcons[platform]}</div>}
                    <h3 className="font-semibold text-[#4B5563] mb-1">
                      {platform.charAt(0).toUpperCase() + platform.slice(1)} Ads
                    </h3>
                    {platformStatus?.status === 'connected' && platformStatus.tokens[0]?.email ? (
                      <p className="text-xs text-gray-600 mb-4">
                        {platformStatus.tokens[0].email}
                      </p>
                    ) : (
                      <p className="text-xs text-gray-600 mb-4">
                        {loadingPlatforms[platform] ? 'Connect Account' : 'Connect Account'}
                      </p>
                    )}
                    <button
                      className={`border border-[#5C6670]/50 font-semibold px-4 py-3 w-full rounded-[8px] transition duration-300 ease-in-out ${
                        !selectedWebsite
                          ? 'bg-gray-300 text-[#fff] font-bold cursor-not-allowed'
                          : platformStatus?.status === 'connected'
                          ? 'border-[#016F52] text-[#08916F] hover:text-[#fff] hover:bg-[#08916F]/90 cursor-pointer'
                          : 'bg-[#08916F] text-white hover:bg-[#08916F]/90 cursor-pointer'
                      }`}
                      disabled={!selectedWebsite || loadingPlatforms[platform] || adPlatformLoading}
                      onClick={() => handlePlatformAction(platform)}
                    >
                      {!selectedWebsite ? (
                        'Connect'
                      ) : loadingPlatforms[platform] ? (
                        <span className="spinner">Loading...</span>
                      ) : platformStatus?.status === 'connected' ? (
                        'Disconnect'
                      ) : (
                        'Connect'
                      )}
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {showModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-xl w-[80%] lg:w-[600px]">
              <h3 className="text-lg font-semibold mb-4">Disconnect {platformToDisconnect}?</h3>
              <p className="mb-6 text-[15px]">Are you sure you want to disconnect {platformToDisconnect} Ads? You will loose the ability to filter by ads from this platform</p>
              <div className="flex justify-end space-x-4">
                <button
                  className="px-4 py-2 bg-gray-200 hover:bg-gray-200/80 rounded-md text-[15px]"
                  onClick={() => {
                    setShowModal(false);
                    setPlatformToDisconnect(null);
                  }}>
                  No, Cancel
                </button>
                <button
                  className="px-4 py-2 bg-[#992400] hover:bg-[#992400]/80 text-white rounded-md text-[15px]"
                  onClick={() => {
                    handleDisconnect();
                    setShowModal(false);
                  }}
                >
                  Yes, Disconnect
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
}