import { useState } from 'react';
import { useAuthHeader, useAuthUser } from 'react-auth-kit';
import { useLogoutUser } from '../../../util/global';

export const useAdPlatformIntegrations = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const authHeader = useAuthHeader();
  const [tokenType, token] = authHeader().split(' ');
  const auth = useAuthUser();

  const { logoutUser } = useLogoutUser();

  type Partner = 'facebook' | 'google' | 'x' | 'tiktok';

  const getAllStatus = async (userId: string, websiteIds: number[]) => {
    setLoading(true);
    setErrorMessage(null);
    const payload = {
      action: 'status',
      userId,
      websiteIds,
    };
  
    try {
      const response = await fetch(
        'https://00ujjwhaed.execute-api.us-west-2.amazonaws.com/Prod/oauth',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        }
      );
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const result = await response.json();
      setLoading(false);
      if (result) {
        return result; // Directly returning the raw result without checking for result.status
      } else {
        setErrorMessage('Failed to fetch platform statuses');
        return null;
      }
    } catch (error) {
      setErrorMessage('Error fetching platform statuses');
      setLoading(false);
      return null;
    }
  };
  
  const disconnectPlatform = async (partner: 'facebook' | 'google' | 'x' | 'tiktok', userId: string, websiteIds: number[]) => {
    setLoading(true);
    setErrorMessage(null);
    const payload = {
      action: 'disconnect',
      partner,
      userId,
      websiteIds
    };
    try {
      const response = await fetch('https://00ujjwhaed.execute-api.us-west-2.amazonaws.com/Prod/oauth', {
        method: 'POST',
        body: JSON.stringify(payload),
      });
      const result = await response.json();
      setLoading(false);
      if (result.status === 'success') {
        return true;
      } else {
        if (result.reason === 'token_expired') {
          await logoutUser();
        }
        setErrorMessage(result.message || `Failed to disconnect ${partner} platform`);
        return false;
      }
    } catch (error) {
      setErrorMessage(`Error disconnecting ${partner} platform`);
      setLoading(false);
      return false;
    }
  };

  const authorizePlatform = async (partner: Partner, userId: string, websiteIds: number[]): Promise<{ url: string; codeVerifier?: string; state?: string } | null> => {
    setLoading(true);
    setErrorMessage(null);
    const payload = {
      action: 'authorize',
      partner,
      userId,
      websiteIds
    };
    try {
      const response = await fetch('https://00ujjwhaed.execute-api.us-west-2.amazonaws.com/Prod/oauth', {
        method: 'POST',
        body: JSON.stringify(payload),
      });
      const result = await response.json();
      setLoading(false);
      if (result && typeof result === 'object' && 'url' in result) {
        if (partner === 'x') {
          return {
            url: result.url,
            codeVerifier: result.codeVerifier,
            state: result.state
          };
        }
        return { url: result.url };
      } else {
        setErrorMessage(`Failed to authorize ${partner} platform: Invalid response format`);
        return null;
      }
    } catch (error) {
      console.error('Error in authorizePlatform:', error);
      setErrorMessage(`Error authorizing ${partner} platform`);
      setLoading(false);
      return null;
    }
  };

  const exchangeCode = async (partner: Partner, code: string, userId: string, websiteIds: number[], twitterCodeVerifier?: string, state?: string) => {
    setLoading(true);
    setErrorMessage(null);
    const payload: any = {
      action: 'exchange',
      partner,
      code,
      userId,
      websiteIds
    };
    
    if (partner === 'x' && twitterCodeVerifier) {
      payload.twitterCodeVerifier = twitterCodeVerifier;
      payload.state = state;
    }
  
    try {
      const response = await fetch('https://00ujjwhaed.execute-api.us-west-2.amazonaws.com/Prod/oauth', {
        method: 'POST',
        body: JSON.stringify(payload),
      });
      const result = await response.json();
      setLoading(false);
      if (result.status === 'success') {
        return result.data;
      } else {
        if (result.reason === 'token_expired') {
          await logoutUser();
        }
        setErrorMessage(result.message || `Failed to exchange auth code for ${partner}`);
        return null;
      }
    } catch (error) {
      setErrorMessage(`Error exchanging auth code for ${partner}`);
      setLoading(false);
      return null;
    }
  };

  return { getAllStatus, disconnectPlatform, authorizePlatform, exchangeCode, errorMessage, loading };
};
