import { useState } from 'react';
import { getRequest } from '../requests';
import { useAuthHeader, useAuthUser } from 'react-auth-kit';
import { useLogoutUser } from '../util/global';

export const useUserSites = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const authHeader = useAuthHeader();
  const [tokenType, token] = authHeader().split(' ');
  const auth = useAuthUser();

  const { logoutUser } = useLogoutUser();

  const loadUserSites = async () => {
    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.getSubscriptions',
          status: 'active',
          token,
          format: 'json'
        }
      });
      if (response.data) {
        if (response.data.result === 'error' && response.data.reason == 'token_expired') {
          await logoutUser();
          setErrorMessage(response.data.msg);
          setLoading(false);
          return;
        }
        setLoading(false);
        return response.data;
      }
    } catch (e) {
      setErrorMessage('Error fetching subscriptions');
      setLoading(false);
    }
  };

  const loadUserProfile = async ({
    auth_token,
    email,
    team,
    account_id
  }: {
    auth_token?: string;
    email?: string;
    team?: boolean;
    account_id?: string | number | null;
  }) => {
    setLoading(true);
    setErrorMessage(null);
    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.getUserProfile',
          email: email ? email : auth() && auth()?.email,
          token: auth_token ? auth_token : token && token,
          account_id,
          filter_limit: -1,
          team,
          format: 'json'
        }
      });
      if (response.data) {
        if (response.data.result === 'error' && response.data.reason == 'token_expired') {
          setLoading(false);
          setErrorMessage(response.data.msg);
          return await logoutUser();
        } else if (response.data.result === 'error') {
          setErrorMessage(response.data.msg);
          setLoading(false);
          return;
        }
        setLoading(false);
        return response.data;
      }
    } catch (e) {
      setErrorMessage('Error fetching user data');
      setLoading(false);
    }
  };
  const getFeaturesListByIdsite = async (idsite: number) => {
    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.getUserProfile',
          token,
          idsite,
          format: 'json'
        }
      });
  
      if (response.data && response.data.subscription_details.subscription) {
        const site = response.data.subscription_details.subscription.find((site: any) => site.website?.idsite === idsite);
        return site?.website?.features_list || '';
      }
      return '';
    } catch (error) {
      console.error('Error fetching features list:', error);
      return '';
    }
  };
  

  return { loadUserSites, loadUserProfile, getFeaturesListByIdsite, errorMessage, loading };
};
